import React, { Component } from 'react';
import { BrowserRouter, Route, Switch,} from 'react-router-dom';
import './scss/style.scss';
import {userProfile, loginURL} from './data/Data'

import { withTranslation } from 'react-i18next';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Reset = React.lazy(() => import('./views/pages/resetPass/ResetPass'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggued: false
    }
  }
  componentDidMount(){
    if(!localStorage.getItem('auth')){
      localStorage.removeItem('auth')
      this.setState({isLoggued: false})

    }else{
      userProfile.getProfile()
      .then(() => {
        this.setState({isLoggued: true})
      })
      .catch(error =>  {
        console.warn(error.response)
        localStorage.removeItem('auth')
        this.setState({isLoggued: false})
        window.location.href = loginURL;
      })
      
    }
  }
  render() {
    
      return (
        <>
          <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/reset-password" name="Reset" render={props => <Reset {...props}/>} />
                  <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
              </React.Suspense>
          </BrowserRouter>
        </>
      );
    }
  
}

export default withTranslation()(App);
