export const logoNegative = ['608 134', `
  <title>Logo App</title>
  <style type="text/css">
	.st0{enable-background:new    ;}
	.st1{fill:#0072FF;}
	.st2{fill:#FF7E00;}
</style>
<sodipodi:namedview  bordercolor="#666666" borderopacity="1" fit-margin-bottom="0" fit-margin-left="0" fit-margin-right="0" fit-margin-top="0" gridtolerance="10" guidetolerance="10" id="namedview323" inkscape:current-layer="Layer_1" inkscape:cx="73.94445" inkscape:cy="21.619195" inkscape:guide-bbox="true" inkscape:pageopacity="0" inkscape:pageshadow="2" inkscape:window-height="774" inkscape:window-maximized="1" inkscape:window-width="1280" inkscape:window-x="0" inkscape:window-y="26" inkscape:zoom="4.8179832" objecttolerance="10" pagecolor="#ffffff" showgrid="false" showguides="true">
	</sodipodi:namedview>
<g id="_x30_2" class="st0">
	<path class="st1" d="M93.4,29.7v1.1c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.8,0.2,1.1,0.5
		c0.3,0.4,0.4,0.8,0.4,1.4c0,0.6-0.1,1-0.4,1.4c-0.1,0.2-0.3,0.3-0.6,0.4C94.7,34,94.5,34,94.2,34c-0.4,0-0.9-0.1-1.2-0.3
		c-0.4-0.2-0.6-0.7-0.6-1.3v-3.2h0.5c0.1,0,0.2,0,0.3,0.1C93.3,29.4,93.4,29.5,93.4,29.7z M95,32.1c0-0.3-0.1-0.6-0.2-0.8
		c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.4,0.1,0.7,0.2,0.8c0.1,0.2,0.3,0.3,0.6,0.3
		c0.3,0,0.5-0.1,0.6-0.3C94.9,32.7,95,32.5,95,32.1z"/>
	<path class="st1" d="M99.4,30.3v2.2c0,0.7-0.2,1.1-0.5,1.3c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3
		c-0.3-0.2-0.5-0.6-0.5-1.3v-2.2h1v2.3c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.3,0.2-0.5v-1.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1L99.4,30.3L99.4,30.3z"/>
	<path class="st1" d="M99.5,32.7h0.9c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.2-0.2-0.3-0.6-0.4c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-0.8-0.3-1-0.4c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9
		c0.3-0.2,0.6-0.3,1.1-0.3c0.5,0,0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9H102c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.2,0.3,0.7,0.4c0.1,0,0.1,0,0.1,0
		c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.3,1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1.2,0.3
		c-0.5,0-0.9-0.1-1.2-0.3C99.6,33.5,99.5,33.2,99.5,32.7L99.5,32.7L99.5,32.7z"/>
	<path class="st1" d="M103.1,29.2h0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V30h-1V29.2z M103.1,30.3h0.5
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V34h-1V30.3z"/>
	<path class="st1" d="M107.4,34h-0.5c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-1.9c0-0.2-0.1-0.4-0.2-0.5
		c-0.1-0.1-0.3-0.2-0.5-0.2s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.5V34h-1v-2.2c0-0.6,0.2-1,0.5-1.3c0.3-0.2,0.7-0.3,1.1-0.3
		c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.6,0.5,1.3L107.4,34L107.4,34z"/>
	<path class="st1" d="M110.1,32.8h0.7c-0.1,0.4-0.3,0.7-0.6,0.9c-0.3,0.2-0.6,0.3-1.1,0.3c-0.5,0-0.9-0.2-1.2-0.5
		c-0.3-0.3-0.4-0.8-0.4-1.4c0-0.6,0.1-1,0.4-1.4c0.3-0.3,0.7-0.5,1.2-0.5c0.6,0,1,0.2,1.3,0.5c0.3,0.3,0.4,0.8,0.4,1.4
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1h-2.4c0,0.3,0.1,0.5,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1
		c0,0,0.1,0,0.1,0s0,0,0.1-0.1c0,0,0-0.1,0.1-0.1C109.9,32.9,110,32.8,110.1,32.8z M108.5,31.7h1.4c0-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2C108.6,31.3,108.5,31.5,108.5,31.7z"/>
	<path class="st1" d="M111,32.7h0.9c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.2-0.2-0.3-0.6-0.4c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-0.8-0.3-1-0.4c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9
		c0.3-0.2,0.6-0.3,1.1-0.3c0.5,0,0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9h-0.6c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.2,0.3,0.7,0.4c0.1,0,0.1,0,0.1,0
		c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.3,1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1.2,0.3
		c-0.5,0-0.9-0.1-1.2-0.3C111.2,33.5,111,33.2,111,32.7L111,32.7L111,32.7z"/>
	<path class="st1" d="M114.4,32.7h0.9c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.2-0.2-0.3-0.6-0.4c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-0.8-0.3-1-0.4c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9
		c0.3-0.2,0.6-0.3,1.1-0.3c0.5,0,0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9H117c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.2,0.3,0.7,0.4c0.1,0,0.1,0,0.1,0
		c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.3,1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1.2,0.3
		c-0.5,0-0.9-0.1-1.2-0.3C114.6,33.5,114.4,33.2,114.4,32.7L114.4,32.7L114.4,32.7z"/>
	<path class="st1" d="M119.7,29.2h0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V30h-1V29.2z M119.7,30.3h0.5
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V34h-1V30.3z"/>
	<path class="st1" d="M122.4,34c-0.3,0-0.6,0-1,0c-0.4,0-0.5-0.3-0.5-0.7v-4.1h0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.6
		h0.6v0.2c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1v2c0,0.2,0.1,0.2,0.3,0.2h0.3V34L122.4,34z"/>
	<path class="st1" d="M124.1,32.7h0.9c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.2-0.2-0.3-0.6-0.4c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-0.8-0.3-1-0.4c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9
		c0.3-0.2,0.6-0.3,1.1-0.3c0.5,0,0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9h-0.6c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.2,0.3,0.7,0.4c0.1,0,0.1,0,0.1,0
		c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.3,1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1.2,0.3
		c-0.5,0-0.9-0.1-1.2-0.3C124.2,33.5,124.1,33.2,124.1,32.7L124.1,32.7L124.1,32.7z"/>
	<path class="st1" d="M128,33.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.6
		c0.2-0.3,0.6-0.4,0.9-0.5c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.4,0.8,0.7c0.2,0.3,0.2,0.7,0.2,1.1c0,0.3,0,0.5-0.1,0.8
		c-0.1,0.2-0.1,0.4-0.2,0.5c-0.2,0.3-0.5,0.5-0.8,0.6c-0.3,0.1-0.6,0.1-0.9,0C128.5,33.9,128.2,33.8,128,33.6z M128.7,31.2
		c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.2,0.9c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0c0.1,0,0.3-0.1,0.4-0.3
		c0.1-0.2,0.2-0.4,0.2-0.8c0-0.3-0.1-0.6-0.2-0.7c-0.1-0.2-0.2-0.3-0.4-0.3c-0.1,0-0.3,0-0.4,0C128.9,31.1,128.8,31.1,128.7,31.2z"
		/>
	<path class="st1" d="M131.1,29.2h0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V34h-1V29.2z"/>
	<path class="st1" d="M135.6,30.3v2.2c0,0.7-0.2,1.1-0.5,1.3c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3
		c-0.3-0.2-0.5-0.6-0.5-1.3v-2.2h1v2.3c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.3,0.2-0.5v-1.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1L135.6,30.3L135.6,30.3z"/>
	<path class="st1" d="M137.4,34c-0.3,0-0.6,0-1,0c-0.4,0-0.5-0.3-0.5-0.7v-4.1h0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.6
		h0.6v0.2c0,0.1,0,0.3-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1v2c0,0.2,0.1,0.2,0.3,0.2h0.3L137.4,34L137.4,34z"/>
	<path class="st1" d="M137.6,29.2h0.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V30h-1L137.6,29.2L137.6,29.2z M137.6,30.3h0.5
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V34h-1L137.6,30.3L137.6,30.3z"/>
	<path class="st1" d="M139.2,33.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.6
		c0.2-0.3,0.5-0.4,0.9-0.5c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.4,0.8,0.7c0.2,0.3,0.2,0.7,0.2,1.1c0,0.3,0,0.5-0.1,0.8
		c-0.1,0.2-0.1,0.4-0.3,0.5c-0.2,0.3-0.5,0.5-0.8,0.6c-0.3,0.1-0.6,0.1-0.9,0C139.6,33.9,139.4,33.8,139.2,33.6z M139.8,31.2
		c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.2,0.9c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0c0.1,0,0.3-0.1,0.4-0.3
		c0.1-0.2,0.2-0.4,0.2-0.8c0-0.3-0.1-0.6-0.2-0.7c-0.1-0.2-0.2-0.3-0.4-0.3c-0.1,0-0.3,0-0.4,0C140,31.1,139.9,31.1,139.8,31.2z"/>
	<path class="st1" d="M145.5,34H145c-0.1,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-1.9c0-0.2-0.1-0.4-0.2-0.5
		c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.5V34h-1v-2.2c0-0.6,0.2-1,0.5-1.3
		c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.8,0.1,1.1,0.3s0.5,0.6,0.5,1.3L145.5,34L145.5,34z"/>
	<path class="st1" d="M145.6,32.7h0.9c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.2-0.2-0.3-0.6-0.4c-0.2,0-0.3-0.1-0.4-0.1c-0.5-0.1-0.8-0.3-1-0.4c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9
		c0.3-0.2,0.6-0.3,1.1-0.3c0.5,0,0.9,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9h-0.6c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.2,0.3,0.7,0.4c0.1,0,0.1,0,0.1,0
		c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.3,1,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.3-1.2,0.3
		c-0.5,0-0.9-0.1-1.2-0.3C145.7,33.5,145.6,33.2,145.6,32.7L145.6,32.7L145.6,32.7z"/>
</g>
<g id="_x30_1">
	<path class="st2" d="M131.5,19.3h5c0,1,0.4,1.7,1.1,2.2c0.7,0.5,1.7,0.7,2.9,0.7c0.9,0,1.6-0.2,2.3-0.5c0.6-0.3,0.9-0.8,0.9-1.4
		c0-0.9-1-1.5-3.1-2c-0.9-0.1-1.5-0.3-2-0.4c-2.6-0.6-4.3-1.4-5.2-2.3c-1-0.9-1.5-2.1-1.5-3.5c0-1.9,0.7-3.4,2.1-4.6
		c1.5-1.1,3.4-1.7,5.7-1.7c2.5,0,4.6,0.6,6.1,1.7c1.4,1.2,2.2,2.8,2.3,4.8H145c-0.9,0-1.6-0.4-2.1-1.2c-0.2-0.2-0.4-0.4-0.6-0.6
		c-0.6-0.4-1.4-0.7-2.4-0.7c-1,0-1.7,0.1-2.2,0.4c-0.4,0.3-0.7,0.7-0.7,1.3c0,0.8,1.3,1.4,3.8,2c0.3,0.1,0.6,0.2,0.8,0.2
		c0.2,0,0.4,0.1,0.6,0.1c2.5,0.6,4.2,1.4,5.2,2.2c0.9,0.9,1.4,2.1,1.4,3.5c0,2.2-0.8,3.8-2.3,5c-1.5,1.1-3.7,1.6-6.6,1.6
		c-2.8,0-4.9-0.6-6.2-1.7c-1.4-1.1-2.1-2.8-2.1-4.9L131.5,19.3L131.5,19.3z"/>
	<path class="st2" d="M130.1,25.8c-1.6,0.1-3.4,0.1-5.2-0.1c-1.9-0.1-2.8-1.3-2.8-3.6V0.6h2.6c0.6,0,1.2,0.2,1.7,0.7
		c0.5,0.5,0.7,1.1,0.7,1.8v3.3h3v1c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7h-0.5v10.9c0,0.8,0.5,1.2,1.5,1.2h1.5L130.1,25.8
		L130.1,25.8z"/>
	<path class="st2" d="M114.7,0.6h2.6c0.7,0,1.3,0.2,1.8,0.7c0.5,0.5,0.7,1.1,0.7,1.8v1.5h-5.1L114.7,0.6L114.7,0.6z M114.7,6.3h2.6
		c0.7,0,1.3,0.2,1.8,0.7c0.5,0.5,0.7,1.1,0.7,1.8v17h-5.1L114.7,6.3L114.7,6.3z"/>
	<path class="st2" d="M98.5,3v5.9c0.5-0.9,1.3-1.7,2.3-2.3c0.9-0.5,2-0.7,3.2-0.7c2.3,0,4.2,1,5.6,2.9c1.5,1.9,2.2,4.4,2.2,7.4
		c0,3-0.7,5.5-2.2,7.4c-0.7,0.9-1.7,1.6-3,2c-1.2,0.4-2.5,0.6-3.7,0.6c-2.3,0-4.5-0.6-6.5-1.8c-2-1.2-3-3.6-3-7V0.5h2.5
		c0.7,0,1.3,0.2,1.8,0.7C98.3,1.7,98.5,2.3,98.5,3z M106.9,15.9c0-1.8-0.4-3.1-1.1-4.1c-0.7-1-1.7-1.5-3-1.5c-1.4,0-2.4,0.5-3.2,1.5
		c-0.7,1-1.1,2.4-1.1,4.2c0,2.1,0.3,3.5,1,4.5c0.7,1,1.8,1.5,3.1,1.5c1.4,0,2.4-0.5,3.2-1.5C106.5,19.3,106.9,17.9,106.9,15.9z"/>
	<path class="st1" d="M91.2,25.8c-1.6,0.1-3.4,0.1-5.2-0.1c-1.9-0.1-2.8-1.3-2.8-3.6V0.6h2.6c0.6,0,1.2,0.2,1.7,0.7
		c0.5,0.5,0.7,1.1,0.7,1.8v3.3h3v1c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7h-0.5v10.9c0,0.8,0.5,1.2,1.5,1.2h1.5L91.2,25.8
		L91.2,25.8z"/>
	<path class="st1" d="M69.6,25.8v-9.5c0-1.9,0.3-3.5,0.8-4.8c0.6-1.3,1.3-2.3,2.3-3.1c0.9-0.7,2-1.3,3.3-1.6
		c1.2-0.3,2.5-0.5,3.9-0.5c0.1,0,0.2,0,0.4,0s0.3,0,0.4,0v5.2h-1.7c-1.4,0-2.5,0.3-3.2,1c-0.7,0.7-1,1.8-1,3.2v10
		C74.7,25.8,69.6,25.8,69.6,25.8z"/>
	<path class="st1" d="M63.9,25c-1,0.5-2.1,0.9-3.3,1c-1.1,0.1-2.2,0.2-3,0.2c-1.8,0-3.3-0.5-4.6-1.4c-1.3-1-2-2.5-2-4.7
		c0-2.1,0.6-3.5,1.8-4.4c1.2-0.9,2.7-1.5,4.6-1.8c0.2,0,0.5,0,0.9-0.1c0.3-0.1,0.7-0.1,1.2-0.2c2.2-0.3,3.2-0.9,3.2-1.9
		c0-0.7-0.4-1.2-1.1-1.4C60.7,10,60,9.9,59.4,9.9c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-0.9,0.6-1.2,1.1h-4.9c0.2-1.5,0.9-2.8,2.1-3.7
		c1.3-1.1,3.2-1.7,5.6-1.7c2.7,0,4.8,0.5,6.2,1.4c1.4,1,2.1,2.3,2.1,4.1V18c0,2-0.4,3.5-1.1,4.6C65.7,23.7,64.9,24.5,63.9,25z
		 M62.7,16c-0.7,0.3-1.6,0.7-2.9,1l-1.7,0.4c-0.9,0.2-1.5,0.6-1.9,1c-0.3,0.5-0.5,1.1-0.5,1.6c0,0.7,0.2,1.3,0.7,1.8
		c0.4,0.4,1.1,0.6,1.9,0.6c1.4,0,2.4-0.4,3.2-1.2c0.8-0.8,1.2-1.9,1.2-3.2L62.7,16L62.7,16L62.7,16z"/>
	<path class="st1" d="M36.7,25.8h-5.1V14.4v-1c0-1.2-0.4-2-1.1-2.6c-0.7-0.5-1.5-0.8-2.4-0.8c-0.9,0-1.7,0.3-2.3,0.8
		c-0.7,0.5-1,1.4-1,2.6v12.5h-5.1V14.4c0-3.3,0.9-5.5,2.6-6.7C24,6.6,25.9,5.9,28,5.8c1.1,0,2.2,0.1,3.3,0.4
		c1.1,0.2,2.1,0.7,2.9,1.5C35,7,35.9,6.6,36.9,6.3c1-0.3,2-0.4,3.1-0.5c2.1,0,4.1,0.6,5.9,1.7c1.8,1.1,2.7,3.4,2.7,6.8v11.4h-2.6
		c-0.7,0-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8v-10c0-1.2-0.4-2-1.1-2.6C41.8,10.2,41,10,40.1,10c-0.9,0-1.7,0.3-2.4,0.8
		c-0.7,0.5-1.1,1.4-1.1,2.6v1L36.7,25.8L36.7,25.8z"/>
	<path class="st1" d="M0.2,19.3h5c0,1,0.4,1.7,1.1,2.2C7,22,8,22.3,9.2,22.3c0.9,0,1.6-0.2,2.3-0.5c0.6-0.3,0.9-0.8,0.9-1.4
		c0-0.9-1-1.5-3.1-2c-0.9-0.1-1.5-0.3-2-0.4c-2.6-0.6-4.3-1.4-5.2-2.3c-1-0.9-1.5-2.1-1.5-3.5c0-1.9,0.7-3.4,2.1-4.6
		c1.5-1.1,3.4-1.7,5.7-1.7c2.5,0,4.6,0.6,6.1,1.7c1.4,1.2,2.2,2.8,2.3,4.8h-3.2c-0.9,0-1.6-0.4-2.1-1.2c-0.2-0.2-0.4-0.4-0.6-0.6
		C10.4,10,9.5,9.8,8.5,9.8c-1,0-1.7,0.1-2.2,0.4c-0.4,0.3-0.7,0.7-0.7,1.3c0,0.8,1.3,1.4,3.8,2c0.3,0.1,0.6,0.2,0.8,0.2
		c0.2,0,0.4,0.1,0.6,0.1c2.5,0.6,4.2,1.4,5.2,2.2c0.9,0.9,1.4,2.1,1.4,3.5c0,2.2-0.8,3.8-2.3,5c-1.5,1.1-3.7,1.6-6.6,1.6
		c-2.8,0-4.9-0.6-6.2-1.7c-1.4-1.1-2.1-2.8-2.1-4.9L0.2,19.3L0.2,19.3z"/>
</g>
`]
