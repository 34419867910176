export const logo = ['608 134', `
  <title>coreui react pro</title>
  <image style="overflow:visible;" width="149" height="50" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJUAAAAyCAYAAABVu5kZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJ
bWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdp
bj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6
eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0
MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJo
dHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlw
dGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEu
MC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVz
b3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1N
Ok9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDoxOTVDRjgzMEJCNDJFMTExQjUwM0YwM0U0NjhG
QjQyQSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo5NjUzOTgxMEMxNjQxMUUzQjM2MkRDMjlB
ODhEREYwNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo5NjUzOTgwRkMxNjQxMUUzQjM2MkRD
MjlBODhEREYwNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93
cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjE4QTIwQzky
NjRDMUUzMTFBMkMxQTE5QzgxNERENkU1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjE5NUNG
ODMwQkI0MkUxMTFCNTAzRjAzRTQ2OEZCNDJBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpS
REY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+PYRTlgAABt9JREFUeNrsXMGRqzgQ
FS7ft5wCEwITAg4Bh2DfvQc4bW39k7nM3d4MxiFACCaEIYQhBK+EWnYjSyAYjPm73VX8mUFCatRP
rdct8b3r9cpISMaUBQ0BCYGKhEBFQqAiISFQkRCoSAhUJCQEKpIZiEdDMKLsrzH/94DuJOzDS/Vq
178Yzjjn/Np5v1j5k655m0f+Y4v75m2mUPaglyojT/X7yYEDbdtRJ9QMPlrfHExhS9mWQPX7iu9Q
J3hS38EP9SJQzVRW/ze9lmTz/wg5/sV2gpvNQZelgWwGsM5vDS4yh+vMCagbsby3FxncccKvird1
0siumEWxVvfMr8JEfFv6juAdTJxF6H+CNnOCpZH8O2FBDzI8zQifYHwXOXFj7FoMqozp0l4BoIkd
3XLK+05a+hZtfMKAuEgdgXVOlP01hImBJ0jJn3uzRH/sYRLxSaFFf3Ub3DBvKFLTJ+AZjHfuEf3l
vP7aEv096KVHg/yZXlgAT6lxqv0169EIq19AgtA2+F892gvgpV3X+Zj3cWkpz3oASkVgGYDR/D6y
vwz0DJ7gFXx+fVnaF+P4ycszfrmOUTg0whP99MUCgBCBSoa94YD+o4eQWRrmOIF3DsAzmHJFQ4zu
azOdobHJRgDSqWXpXkEfvhP4xyXjJ+ylAIiDsKBAvLh5HfMgeLeLsXdYJkwv2vRg5sFJUVtv4NJt
UsFypOqvYYl89FgmoLf1fX+XU+e7SI97HAFMXitVkMZ3DfEDbrwx8loCTB5ettqwAHXrqwsLC2s+
Qx+ED08Q2rUBDKFDbiRpcCDBXT68TQuwNg3yLon0Gsh10xjS8FjP9waA5JUY3mXnMLOn8Lg6t1wj
41knk1gup8pt6cDjfxfA14xYWLaQ0oORDEsw9E242aKrs8GzmKOxD6/iOp0N3iloab+LdNtBdY8c
TTqnNTDHlRIAVSHj5Rw8wngXgy5Rrcc0UeCB65IYwLZpSykUBjDEwE9SCPtdX8C3LGfM8X7VsSwO
E8n1tmhGdfGGyAio7kk1VFIMKGS4ihs1NXjNZ4HKiAWIIGssdO0bLm/rvt3VxzfPda9bNJanOcod
RFs2bEvC5qWeJeeOsqPDijCGdGIBcboaCxxkDSwsYGk5WYirOZUgOt1fvx02S18FKPHy3xCeD+Ue
vmUWP0Mqk5fC3srAJ9kzeBUApBcWuB7fOH2x0Ij5psfArQBchxkCqk2nnMlM/pvJUD3D8dFANach
BGLeGwvKgy01Mlxnbju2VpiBe+Wz2OqQS15scemnB3K9n82HtL6j4aYEVo0FtFXjhAVePzdHf3Lw
ixsRbN8PHB6BjS+hYfCTjqSjHoG9RESm3LYEQhZ9ZTB8OQG4Gljo2A+ssbC0LBeplle6N2zeH/Rn
MuNXzjxITpRXHUsxRVhRC5eJpuB3lj3CFKcTMMgs+4P+wjI7o458yqw5gSPgDxMScZcxjE37enAv
tnDDKfTqjYWFZSD9eoNZJgB1Ehy/0BhDCG8MXkm9Qwib5+FEhgoc0wcC/Bk+Agy/Z09KbwSOdvRh
IzsyeDUjFpb1lokg2o+DLBOE3WS2mgmfUqCotGVNGOTi8B7PCjbExvcV8ztBgrlRTEtgAMDqzGnB
MvQjvdARnProi+BogmjbsOCgV40FlVLY/WAJ29RbKHMQqceQLHPJxjk1WfYat2GBwRBd+/TzIyyI
YEMlP+t9p56dy2emTSUUDsBKewIrh/cYI5LKXQ0ivAIczOuta1uidAy9hmJB7FWKPxaNCE+eYNwx
xpIW95YweSzlbfLclOwvcagn6ry31C2g7L0+eTEOoJSnXPehAyKyghMJiQVgJZQJo62HpBEAhM56
wSkEZyyIugpQ9fP0P+mRjC30iRYJgYpk/kLf/b1AvD9v+Z0ukh5BOD80MhXPH5j6Wqh/XxnwpiNw
MieyT57qNeKDEcXXM3/DT/XBSAx/q6Snuv8FZapcbJGIvNYFrgieUfWUrBBgGIDsC9oM4dkM6oWa
LvibyS16zodnjP2Sp3qdqJzaP/z6Axl1BdFpBVfE7rmpDO6d4P4FynIwtADZmd0z8GojWtVRBxdV
nSN4oi1rfiKndPFBRwWkDbt/T1lAm+q5W7/kqV4LqhIAhSWFsoA19ydLZPAKjKrOPPngJXLkmVaW
PtWmdYDarVj35joGu49A7+v9kqd6jZxgxn/C7yvkhULkaRSfYciIBXiWCnknxponH0rEf0pk8Bx5
wwrpsUIeq0C6KE/JwBNdkP4M9dHol/JUryHq+tKkeBb+vdI8SIXAoJevEHhwm8xyT/c0laH9UlsO
S1SXaTrhOpT8JKE8FQmBioRARUJCoCIhUJEQqEhIhsi/AgwAKyuvieVqoRYAAAAASUVORK5CYII=" transform="matrix(1 0 0 1 0 -6)">
</image>
`]
