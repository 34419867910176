import axios from 'axios'
//const url_backend = `https://cocovita-api.smartbits.es`
const url_backend = process.env.REACT_APP_API_URL_DEVELOP
//const url_backend = `http://127.0.0.1:8000`

export const loginURL = `${url_backend} /login`

export const auth = {
    loginAdm: function(email, password){
        return axios.post(url_backend+`/api/auth/loginadm`, {
            email: email,
            password: password
        })
    },
    forgotPass: function(email){
        return axios.post(url_backend+`/api/auth/forgot-password`, {
            email: email
        })
    },
    resetPass: function(token, email, password, password_confirmation){
        return axios.post(url_backend+`/api/auth/reset-password`, {
            token: token,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        })
    }
}

export const userProfile = {
    getProfile: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/auth/user-profile`)
    },
    logOut: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/auth/logout`)
    },
    updateProfile: function(id, name, email, provincia_id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/users/user/${id}`, {
            name: name,
            email: email,
            provincia_id: provincia_id
        })
    },
    updatePassword: function(id, password, new_password){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/users/userupdatepass/${id}`, {
            password: password,
            new_password: new_password
        })
    }
}


export const userData = {
    getUsersPendingCount: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/validations/pendingvalidationscount`)
    },
    getUsersPending: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/validations/pendingvalidations`)
    },
    putUsersValidate: function(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/validations/validate/${id}`)
    },
    getUsers: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/users/users`)
    },
    getUser: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/users/showuser/${id}`)
    },
    updateUser: function(id, name, surnames, pais_id, provincia_id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/users/showuser/${id}`, {
        name: name,
        surnames: surnames,
        pais_id: pais_id,
        provincia_id: provincia_id
        })
    },
    resetPassUser: function(email){
        //axios.defaults.headers.common['Authorization'] = ""
        return axios.post(url_backend+'/api/auth/forgot-password', {
        email: email
        })
    },
    blockUser: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/users/blockuser/${id}`)
    },
    unblockUser: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/users/unblockuser/${id}`)
    },
    deleteUser: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.delete(url_backend+`/api/users/user/${id}`)
    },
    recoverUser: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/users/restoreuser/${id}`)
    },
    getProvincias: function(id){
        return axios.get(url_backend+`/api/provincias/provincias`)
    },
    getPaises: function(id){
        return axios.get(url_backend+`/api/paises/paises`)
    },
    registerUser: function(name, surnames, email, pais_id, provincia_id, rol, codigos){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/auth/registeradm`, {
        name: name,
        surnames: surnames, 
        email: email, 
        pais_id: parseInt(pais_id),
        provincia_id: parseInt(provincia_id),
        rol: rol,
        codigos: codigos
        })
    },
    rechazarUsuario: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.delete(url_backend+`/api/validations/validation/${id}`)
    },
    getUsersWithDel: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/validations/pendingvalidationsdelete`)
    },
    saveSoportePwd:function(id,password){

        const data = {
            id:id,
            password:password
        }
        return axios.post(url_backend+`/api/users/soportepwd`, data)
    },
    getIp: function(){
        return axios.post(url_backend+`/api/users/getIp`)
    }
}

export const graficos = {
    getRegistrosCount: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/registroscount`)
    },
    getRegistrosFechas: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/registrosfechas`)
    },
    getUsuariosCount: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/usuarioscount`)
    },
    patMax: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/patmasusada`)
    },
    genMax: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/genmasusada`)
    },
    /**
     * Porcentajes por enfermedad
     */
    parkPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/tipospatologiaspark`)
    },
    esenPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/tipospatologiasesen`)
    },
    distoPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/tipospatologiasdisto`)
    },
    tocPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/tipospatologiastoc`)
    },
    otrosPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/tipospatologiasotros`)
    },
    /**
     * Porcentajes por tipos de recambios
     */
    kinetraPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/modelogenkinetra`)
    },
    soletraPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/modelogensoletra`)
    },
    activaScPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/modelogenactivasc`)
    },
    activaPcPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/modelogenactivapc`)
    },
    activaRcPor: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/datos/modelogenactivarc`)
    },
}

export const codeGen = {
    genCodes: function(codigos, descripcion){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/invicodes/invicode`, {
            codigos: codigos,
            descripcion: descripcion
        })
    },
    getCodes: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/invicodes/invicode`)
    },
    getCodesOnly: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/invicodes/invicodeonlymy`)
    },
    editCodes: function(descripcion = '', estado = '', id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/invicodes/invicode/${id}`, {
            estado: estado,
            descripcion: descripcion
        })
    },
    editEstados: function(codigos, estado){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/invicodes/invicodeestado`, {
            codigos: codigos,
            estado: estado
        })
    },
    enviarCode: function(id, email, estado, lang){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/invicodes/invicodesendmail`, {
            id: id,
            email: email,
            estado: estado,
            lang: lang
        })
    },
    generaExcel: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/invicodes/excel`, {responseType: 'arraybuffer'})
    },
    descargaExcel: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/invicodes/download`, {responseType: 'arraybuffer'})
    }
}